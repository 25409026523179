/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

// GENERAL
// ---------------

$color1:              #166131;
$color2:              #6eceed;
$color3:              #009FD2;
$color4:              #191616;
$color5:              #f26322;
$color6:              #5d3e98;
$color7:              #310f71;
$color8:              #66a97e;
$color9:              #e1ebe4;
$color10:              #f2f1ea;
$color11:              #F8F7F3;




// TYPOGRAPHY
// ---------------

$textColor:           #333;

$baseFontSize:        16px;
$em-base:             $baseFontSize;
$baseLineHeight:      1.612;
$baseTypeSpacing:     round($baseFontSize * $baseLineHeight);
$baseFontFamily:      "Arial", sans-serif;
$secondaryFontFamily: $baseFontFamily;

$headingsFontFamily:  $secondaryFontFamily;
$headingsColor:       $color1;

$hrColor:             $color1;
